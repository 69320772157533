<template lang="">
    <div class="col-md-12 vh-center   ">
        <b-form @submit.prevent="onSubmit">
        <b-row>
            <b-col cols="12" md="6">
                <b-card header="ຈອງບັດນັດກົດບັດຄິວອອນລາຍ" class="text-left ">
                    <b-container fluid>
    
                        <b-row class="my-1">
                            <b-col sm="3">
                                <label>ສູນອອກໜັງສືຜ່ານແດນ:</label>
                            </b-col>
                            <b-col sm="9">
                                <b-form-select v-model="fld.unit_id" :options="units" @input="onGetRangefree" required>
    
                                </b-form-select>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="3">
                                <label></label>
                            </b-col>
                            <b-col md="8" gl="8">
                     
                            
                                       
                                  <b-button  block variant="primary"  @click="onShowmodalfull" >ກວດເບິ່ງວັນທີ່ທີ່ເຕັມແລ້ວ...</b-button>
                                     
                            </b-col>
                        </b-row>
                        <b-row class="my-1">
                            <b-col sm="3">
                                <label>ວັນ ເດືອນ ປີ ຕ້ອງການຈອງບັດຄິວ:</label>
                            </b-col>
                            <b-col sm="9">

                                <!-- 
                                    <b-form-datepicker dropleft v-model="fld.queue_date" locale="lao" @input="onGetRangefree" aria-required="true" required></b-form-datepicker>
                                -->
                                <b-row> 
                                <b-col>
                                    <b-form-select placeholder="ວັນທີ"   v-model="ddd" :options="days" required>
                                        <template #first>
                                            <b-form-select-option @change="onGetRangefree" :value="null" disabled>-- ວັນທີ --</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                </b-col>
                                <b-col>
                                    <b-form-select  placeholder="ເດືອນ"  v-model="mmm" :options="months" required>
                                        <template #first>
                                            <b-form-select-option  @change="onGetRangefree" :value="null" disabled>-- ເດືອນ --</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-col>
                                <b-col>
                                    <b-form-input @input="onGetRangefree" maxlength="4"  placeholder="-- ປີ --" v-model="yyy" type="text" required></b-form-input>
                                </b-col>
                           </b-row>
                            </b-col>
    
                        </b-row>
<!--     
                        <b-row class="my-1">
                            <b-col sm="3">
                                <label>ຊ່ວງເວລາທີ່ຍັງມີຄິວວ່າງ:</label>
                            </b-col>
                            <b-col sm="9">
                                <b-form-select v-model="fld.range_id" :options="ranges" required>
    
                                </b-form-select>
                            </b-col>
                        </b-row> -->
    
    
                        <b-row class="my-1">
                            <b-col sm="3">
                                <label>ຊື່:</label>
                            </b-col>
                            <b-col sm="9">
                                <b-form-input v-model="fld.fname" type="text"  required trim></b-form-input>
                            </b-col>
                        </b-row>
    
                        <b-row class="my-1">
                            <b-col sm="3">
                                <label>ນາມສະກຸນ:</label>
                            </b-col>
                            <b-col sm="9">
                                <b-form-input v-model="fld.lname" type="text" required trim></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="3">
                                <label>ເພດ:</label>
                            </b-col>
                            <b-col sm="9">
                                <b-form-radio-group v-model="fld.sex" :options="sex" class="mb-3" value-field="item" text-field="name" required></b-form-radio-group>
                            </b-col>
    
                        </b-row>
                        <b-row class="my-1">
                            <b-col sm="3">
                                <label>ວັນ ເດືອນ ປີ ເກິດ:</label>
                            </b-col>
                            <b-col sm="9">
                                <!-- <b-form-datepicker dropleft v-model="fld.dob" locale="lao" @input="onDateDiff" aria-required="true" required></b-form-datepicker>
                           -->
                           <b-row>
                                <b-col sm="4" dm="12">
                                    <b-form-select placeholder="ວັນທີ"   v-model="dd" :options="days" required @change="onDateDiff">
                                        <template #first>
                                            <b-form-select-option :value="null" disabled>-- ວັນທີ --</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                </b-col>
                                <b-col  sm="4" dm="12">
                                    <b-form-select  placeholder="ເດືອນ"  v-model="mm" :options="months" required @change="onDateDiff">
                                        <template #first>
                                            <b-form-select-option :value="null" disabled>-- ເດືອນ --</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-col>
                                <b-col sm="4" dm="12">
                                    <b-form-input maxlength="4" placeholder="-- ປີ --" :state="validation" v-model="yyyy" type="text" @input="onDateDiff" required></b-form-input>
                                    <b-form-invalid-feedback :state="validation">
                                        ປີຕ້ອງພີມ4ຕົວເລກ.
                                    </b-form-invalid-feedback>
                                    
                                </b-col>
                           </b-row>
                           
                           
                          
                            </b-col>
    
                        </b-row>
                        <b-row class="my-1">
                            <b-col sm="3">
                                <label>ເບີໂທ:</label>
                            </b-col>
                            <b-col sm="9">
                                <b-form-input v-model="fld.tel"  placeholder="ເບີໂທລະສັບມືຖື...." type="text" required  trim ></b-form-input>
                            </b-col>
                        </b-row>
                        <!-- <b-row class="my-1">
                                    <b-col sm="3">
                                        <label>ອາຍຸ:</label>
                                    </b-col>
                                    <b-col sm="9">
                                        {{fld.age}} ປີ
                                    </b-col>
                    
                                </b-row> -->
                                <b-button block variant="primary" type="submit"  >
                            <b-icon icon="check-circle"></b-icon> ຈອງບັດຄິວ </b-button>
                        <!-- <b-button @click.prevent="onPostRTIS">Test HTTPS RTIS</b-button>  -->
                    </b-container>
                </b-card>
            </b-col>
            <b-col>
               
            </b-col>
        </b-row>
        </b-form>
        <b-modal id="modal-1" title="ຂໍ້ຜິດພາດ" hide-footer ref="modal-1">
            <p class="my-4">{{msg}}</p>
        </b-modal>
        <b-modal id="modal-2" title="ວັນທີຈອງເຕັມແລ້ວ...." hide-footer ref="modal-2">
            <b-table :fields="fields" :items="items"></b-table>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
//const getmac = require('getmac')
import getmac from "getmac"
export default {
    created() {
        axios
            .get(
                this.$store.getters.getBaseUrl + "/getItems/consular_units/unit_name/unit_id",{
                    headers :{ "Content-Type": "application/json",
                    Authorization: 'Bearer anUrbLQdEVK7V/w3Mjkp28ZDJXgDH7SpKeqtKBjkqMnNJT4OQ?ukI8w7M5/HmxsONU78KEg4p=!5r0WOf=5/zBa9h/PTI42lb5JEhR855=LTAt!!AeJBFkmFtrpOwS7KhMuk!=B=8Rn=aqz6U59ZoPATYEKFkaZ-C/4ZRUXjUfb4DA0-69KsueEHkI/RW8mD2lLudpbe5JZpti=uQmQe5Nq2G6pP9//DX9hGm3yFpVMInuh-O50nbYdMej1miJTs',
                    "Accept-Encoding": "identity" 
                    }
                }
            )
            .then((response) => {
                // JSON responses are automatically parsed.
                this.units = response.data

            })
            .catch((e) => {
                console.log(e);
            });


    },
    methods: {
        onContext(ctx) {
            // The date formatted in the locale, or the `label-no-date-selected` string
            this.formatted = ctx.selectedFormatted
            // The following will be an empty string until a valid date is entered
            this.selected = ctx.selectedYMD
        },
        onNext() {
            let info = JSON.stringify(this.fld)
            this.$router.push('/pageqa1/' + this.fld.age + "?c=" + info)
        },
        onDateDiff() {

            this.fld.dob = this.yyyy + '-' + this.mm + '-' + this.dd
            let d2 = new Date();
            let d1 = new Date(this.fld.dob);

            let diff = d2.getTime() - d1.getTime();

            this.fld.age = Math.ceil(diff / (1000 * 60 * 60 * 24) / 365);
        },

        onDateDiff_15days() {

            this.fld.queue_date = this.yyy + '-' + this.mmm + '-' + this.ddm
            let d2 = new Date();
            let d1 = new Date(this.fld.queue_date);

            let diff = d2.getTime() - d1.getTime();

            let days = Math.ceil(diff / (1000 * 60 * 60 * 24) / 365);

            if (days > 15) {
                return 0
            }
            return 1
        },

        onGetRangefree() {
            this.fld.queue_date = this.yyy + '-' + this.mmm + '-' + this.ddd
            axios
                .get(
                    this.$store.getters.getBaseUrl + "/listfull/" + this.fld.unit_id,
                    {
                        headers :{ "Content-Type": "application/json",
                    Authorization: 'Bearer anUrbLQdEVK7V/w3Mjkp28ZDJXgDH7SpKeqtKBjkqMnNJT4OQ?ukI8w7M5/HmxsONU78KEg4p=!5r0WOf=5/zBa9h/PTI42lb5JEhR855=LTAt!!AeJBFkmFtrpOwS7KhMuk!=B=8Rn=aqz6U59ZoPATYEKFkaZ-C/4ZRUXjUfb4DA0-69KsueEHkI/RW8mD2lLudpbe5JZpti=uQmQe5Nq2G6pP9//DX9hGm3yFpVMInuh-O50nbYdMej1miJTs',
                    "Accept-Encoding": "identity" 
                    }
                    }
                )
                .then((response) => {
                    // JSON responses are automatically parsed.
                    this.items = response.data

                })
        },
        onSubmit() {
            let params = {
                table: "queue_details",
                rows: this.fld
            }
            this.fld.macaddress = getmac.default
            this.fld.queue_date = this.yyy + '-' + this.mmm + '-' + this.ddd

            let n = 1
            let queue_reg = localStorage.getItem('queue_reg')
            let queue_check = JSON.parse(queue_reg)
            if (queue_check != null) {
                n = queue_check.total + 1
            }
            // if(this.fld.queue_date==""){
            //     this.$bvToast.toast('ກະລຸນາເລືອກວັນທີຕ້ອງການສຳພາດ.', {
            //         title: `ຄຳແນະນຳ`,
            //         variant: 'danger',
            //         solid: true
            //         })
            //         return
            // }



            this.onDateDiff
            console.log(this.yyyy.length)

            if (this.yyyy.length < 4) {
                this.msg = "ທ່ານປ້ອນປີເກີດບໍ່ຄົບ 4ຕົວເລກ."
                this.$refs['modal-1'].show()
                this.yyyy = ""
                return
            }

            if (this.fld.age == 0 || this.fld.age == null) {
                this.$bvToast.toast('ກະລຸນາປ້ອນຂໍ້ມູນວັນເດືອນປີເກີດ.', {
                    title: `ຄຳແນະນຳ`,
                    variant: 'danger',
                    solid: true
                })
                return
            }

            this.fld.dob = this.yyyy + '-' + this.mm + '-' + this.dd
            this.fld.queue_date = this.yyy + '-' + this.mmm + '-' + this.ddd

            axios.post(this.$store.getters.getBaseUrl2 + "/request_queue_no", params, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer anUrbLQdEVK7V/w3Mjkp28ZDJXgDH7SpKeqtKBjkqMnNJT4OQ?ukI8w7M5/HmxsONU78KEg4p=!5r0WOf=5/zBa9h/PTI42lb5JEhR855=LTAt!!AeJBFkmFtrpOwS7KhMuk!=B=8Rn=aqz6U59ZoPATYEKFkaZ-C/4ZRUXjUfb4DA0-69KsueEHkI/RW8mD2lLudpbe5JZpti=uQmQe5Nq2G6pP9//DX9hGm3yFpVMInuh-O50nbYdMej1miJTs',
                    "Accept-Encoding": "identity"
                }
            })
                .then(res => {
                    if (res.data.code == 1) {

                        axios
            .get(
                this.$store.getters.getBaseUrl + "/v_queue_details/queue_id/" + res.data.last_code, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer anUrbLQdEVK7V/w3Mjkp28ZDJXgDH7SpKeqtKBjkqMnNJT4OQ?ukI8w7M5/HmxsONU78KEg4p=!5r0WOf=5/zBa9h/PTI42lb5JEhR855=LTAt!!AeJBFkmFtrpOwS7KhMuk!=B=8Rn=aqz6U59ZoPATYEKFkaZ-C/4ZRUXjUfb4DA0-69KsueEHkI/RW8mD2lLudpbe5JZpti=uQmQe5Nq2G6pP9//DX9hGm3yFpVMInuh-O50nbYdMej1miJTs',
                    "Accept-Encoding": "identity"
                }
            }
            )
            .then((response) => {
                // JSON responses are automatically parsed.
              //  this.fld = response.data[0]
                //  let  qr= [this.fld.queue_id, this.fld.queue_no, this.fld.fname, this.fld.lname, this.fld.age, this.fld.sex, this.fld.dob, this.fld.queue_date,this.fld.start_time,this.fld.end_time, this.fld.ca1, this.fld.ca2, this.fld.ca3, this.fld.ca4, this.fld.ca5, this.fld.ca6, this.fld.ca7, this.fld.ca8, this.fld.ca9, this.fld.ca10, this.fld.ca11, this.fld.ca12, this.fld.ca13, this.fld.ca14, this.fld.ca15]
              //  let qr = [this.fld.queue_id, this.fld.queue_no, this.fld.fname, this.fld.lname, this.fld.age, this.fld.sex, this.fld.dob, this.fld.queue_date, this.fld.start_time, this.fld.end_time, this.fld.ca1]

               // this.qr_code = qr.join("|")
             

               localStorage.setItem("qr_code",JSON.stringify( response.data[0]) )
              //  this.$router.push({path: '/qr/'+queu_id});
              this.$router.push('/qr/' + res.data.last_code)

            })
                     //   this.$router.push('/qr/' + res.data.last_code)
                        let reg = {
                            total: n, date_reg: new Date()
                        }
                        localStorage.setItem("queue_reg", JSON.stringify(reg));
                        console.log(res)
                    } else {
                        //     this.$bvToast.toast(  res.data.desc , {
                        // title: `ຂໍ້ຄວາມ`,
                        // variant: 'danger',
                        // solid: true
                        // })
                        this.msg = res.data.desc
                        this.$refs['modal-1'].show()
                        return
                    }

                })
                .catch(err => {
                    console.error(err);
                    // this.msg=err 
                })
        },
        onShowmodalfull() {
            axios
                .get(
                    this.$store.getters.getBaseUrl + "/listfull_pro/" + this.fld.unit_id,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: 'Bearer anUrbLQdEVK7V/w3Mjkp28ZDJXgDH7SpKeqtKBjkqMnNJT4OQ?ukI8w7M5/HmxsONU78KEg4p=!5r0WOf=5/zBa9h/PTI42lb5JEhR855=LTAt!!AeJBFkmFtrpOwS7KhMuk!=B=8Rn=aqz6U59ZoPATYEKFkaZ-C/4ZRUXjUfb4DA0-69KsueEHkI/RW8mD2lLudpbe5JZpti=uQmQe5Nq2G6pP9//DX9hGm3yFpVMInuh-O50nbYdMej1miJTs',
                            "Accept-Encoding": "identity"
                        }
                    }
                )
                .then((response) => {
                    // JSON responses are automatically parsed.
                    this.items = response.data

                })
            this.$bvModal.show('modal-2')
        },
    },
    data() {
        return {
            msg: "",
            ddd: new Date().getDate(),
            mmm: new Date().getMonth() + 1,
            yyy: new Date().getFullYear(),
            dd: null,
            mm: null,
            yyyy: "",
            fields: [{ key: "queue_date_f", label: "ວັນທີ" }, { key: "total", label: "ຈຳນວນ" }, { key: "remark", label: "ໝາຍເຫດ" }],
            items: [],
            fld: {

                fname: "",
                lname: "",
                sex: "",
                dob: "",
                unit_id: 1,
                "age": 0,
                "ca1": false,
                "ca2": false,
                "ca3": false,
                "ca4": false,
                "ca5": false,
                "ca6": false,
                "ca7": false,
                "ca8": false,
                "ca9": false,
                "ca10": false,
                "ca11": false,
                "ca12": false,
                "ca13": false,
                "ca14": false,
                "ca15": false,
                macaddress: "",
                queue_no: "",
                queue_date: "",
                range_id: "0"
            },
            sex: [
                { item: 'M', name: 'ຊາຍ' },
                { item: 'F', name: 'ຍິງ' },
            ],
            age: '',
            dob: '',
            units: [],
            t: [],
            ranges: [],

            days: [
                { value: '1', text: '01' },
                { value: '2', text: '02' },
                { value: '3', text: '03' },
                { value: '4', text: '04' },
                { value: '5', text: '05' },
                { value: '6', text: '06' },
                { value: '7', text: '07' },
                { value: '8', text: '08' },
                { value: '9', text: '09' },
                { value: '10', text: '10' },
                { value: '11', text: '11' },
                { value: '12', text: '12' },
                { value: '13', text: '13' },
                { value: '14', text: '14' },
                { value: '15', text: '15' },
                { value: '16', text: '16' },
                { value: '17', text: '17' },
                { value: '18', text: '18' },
                { value: '19', text: '19' },
                { value: '20', text: '20' },
                { value: '21', text: '21' },
                { value: '22', text: '22' },
                { value: '23', text: '23' },
                { value: '24', text: '24' },
                { value: '25', text: '25' },
                { value: '26', text: '26' },
                { value: '27', text: '27' },
                { value: '28', text: '28' },
                { value: '29', text: '29' },
                { value: '30', text: '30' },
                { value: '31', text: '31' },

            ],
            months: [
                { value: '1', text: '01' },
                { value: '2', text: '02' },
                { value: '3', text: '03' },
                { value: '4', text: '04' },
                { value: '5', text: '05' },
                { value: '6', text: '06' },
                { value: '7', text: '07' },
                { value: '8', text: '08' },
                { value: '9', text: '09' },
                { value: '10', text: '10' },
                { value: '11', text: '11' },
                { value: '12', text: '12' },
            ]
        }
    },
    computed: {
        validation() {
            return this.yyyy.length >= 4
        }
    },
    filters: {
        formatDate(dd) {
            let d = new Date(dd)
            return [
                (d.getDate().toString().padStart(2, '0')),
                (d.getMonth() + 1).toString().padStart(2, '0'),
                d.getFullYear(),
            ].join('/');
        },
        dateString(dd) {
            let op = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
            const d = new Date(dd);

            return d.toLocaleString('lao', op);
        },
    }

}
</script>

<style lang="">

</style>